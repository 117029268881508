.swiper {
  width: 100%;
  height: 100%;
  overflow: visible !important;
  position: relative !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  position: relative !important;
  overflow: visible;
  /* Center slide text vertically */
  height: 240px;
  width: 370px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-button-next {
  position: absolute;
  top: calc(100% + 50px);
  left: calc(100% - clamp(20px, 10vw, 80px));
  width: 60px;
  height: 60px;
  transform: rotate(180deg);
}
.swiper-button-prev {
  position: absolute;
  width: 60px;
  height: 60px;
  top: calc(100% + 50px);
  left: 0;
}
.workshop-swiper-button-next {
  width: 60px;
  height: 60px;
  transform: rotate(180deg);
}
.workshop-swiper-button-prev {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .swiper-slide {
    width: 270px;
  }
}
